// src/pages/OwnersPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Paper, Box, Button, Typography, LinearProgress, Chip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import DataViewWrapper from '../components/DataViewWrapper';
import { usePaginatedData } from '../hooks/usePaginatedData';
import { fetchOwnersPaginated } from '../services/v2/ownerService';

import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { usePreferences } from '../context/UserPreferencesContext';
import { usePermissions } from '../context/PermissionsContext';

const OwnersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { apiKey, organizationId, token } = useAuth();
  const { devicePreferences, updateDevicePreferences } = usePreferences();
  const { entitlements } = usePermissions();

  // Decide initial view mode based on stored preferences or mobile
  const [viewMode, setViewMode] = useState(
    devicePreferences.viewMode ? devicePreferences.viewMode : isMobile ? 'card' : 'table'
  );

  // Use the new paginated hook for Owners
  const {
    items: owners,
    continuationToken,
    loading: isLoading,
    loadNextPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchOwnersPaginated(apiKey, organizationId, token, options),
    { pageSize: 25 }
  );

  // Toggle between table & card, also store preferences
  const toggleViewMode = () => {
    const newMode = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newMode);
    updateDevicePreferences('viewMode', newMode);
  };

  // Quick filter chips
  const [activeFilter, setActiveFilter] = useState('All');

  // For your entitlements usage
  const hasHorseManagement = entitlements.some(entitlement =>
    entitlement.features.some(feature => feature.name === 'horse-management' && feature.status === 'enabled')
  );

  const handleAddOwner = () => {
    navigate('/owners/new');
  };

  // Example utility functions
  const getName = (owner) => {
    return owner.ownerType === 'company'
      ? owner.companyName
      : `${owner.firstname} ${owner.surname}`;
  };

  const getOwnerType = (ownerType) => {
    if (ownerType === 'private') return t('OwnersPage.private');
    if (ownerType === 'company') return t('OwnersPage.company');
    return ownerType;
  };

  const getEntityTypes = (entityType) => {
    let result = [];
    if (entityType.includes('owner')) {
      result.push(t('OwnersPage.owner'));
    }
    if (entityType.includes('customer')) {
      result.push(t('OwnersPage.customer'));
    }
    if (entityType.includes('vendor')) {
      result.push(t('OwnersPage.vendor'));
    }
    return result.join(', ');
  };

  // Handle manual chip-based filter changes (entityType)
  // This sets the DataViewWrapper "filter" object so server can respond
  useEffect(() => {
    if (activeFilter === 'All') {
      // Clear all filters
      setFilter({});
    } else {
      // Example: filter by entityType
      // Because your API is flexible, we can do "filter.entityType = *owner*" for partial matches.
      setFilter({ entityType: `*${activeFilter}*` });
    }
  }, [activeFilter, setFilter]);

  // Build the "structure" for DataViewWrapper
  // Headers define the columns (and also appear in the filter/sort dropdowns).
  // Rows must map each record to { id, key: { value, icon? }, ... }.
  const structure = {
    headers: [
      { key: 'partnerType', label: t('OwnersPage.table.partnerType'), display: { value: true, chipColor: false } },
      { key: 'entityTypes', label: t('OwnersPage.table.entityType'), display: { value: true, chipColor: false } },
      { key: 'name', label: t('OwnersPage.table.name'), display: { value: true, chipColor: false } },
    ],
    rows: owners.map(owner => ({
      id: owner.id,
      partnerType: {
        value: getOwnerType(owner.ownerType),
      },
      entityTypes: {
        value: getEntityTypes(owner.entityType),
      },
      name: {
        value: getName(owner),
      },
    })),
  };

  // Render
  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('OwnersPage.title')}
      </Typography>
            {/* Loading indicator */}
      {isLoading && <LinearProgress />}

      {/* Main Data View */}
      <DataViewWrapper
        structure={structure}
        viewMode={viewMode}
        onViewModeToggle={toggleViewMode}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
        pageId="/owners"
        onRowClick={(rowId) => navigate(`/owners/${rowId}`)}
        toolbarActions={[
          <Button
            key="add-owner"
            variant="contained"
            color="primary"
            onClick={handleAddOwner}
            startIcon={<AddIcon />}
          >
            {t('OwnersPage.addButton')}
          </Button>,
        ]}
        predefinedFilters={[
          {
            field: 'entityType',
            value: 'owner',
            label: t('OwnersPage.owners'),
          },
          {
            field: 'entityType',
            value: 'customer',
            label: t('OwnersPage.customers'),
          },
          {
            field: 'entityType',
            value: 'vendor',
            label: t('OwnersPage.vendors'),
          },
        ]}

      />

      {/* "Load More" if we still have continuationToken */}
      {continuationToken && (
        <Box mt={2} textAlign="center">
          <Button variant="contained" onClick={loadNextPage} disabled={isLoading}>
            {t('OwnersPage.loadMore')}
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default OwnersPage;
