// src/pages/HorsePage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchHorsesPaginated } from '../services/v2/horseService';
import { usePaginatedData } from '../hooks/usePaginatedData';

import {
  Paper,
  Box,
  Button,
  Typography,
  LinearProgress,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import CakeIcon from '@mui/icons-material/Cake';


import SnackbarAlert from '../components/SnackbarAlert';
import ConfirmationDialog from '../components/ConfirmationDialog';
import DataViewWrapper from '../components/DataViewWrapper';

import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { usePreferences } from '../context/UserPreferencesContext';

import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import EyeIcon from '@mui/icons-material/Visibility';

const HorsePage = () => {
  const { apiKey, userId, userType, organizationId, token } = useAuth();
  const { devicePreferences, updateDevicePreferences } = usePreferences();
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: '' });
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [selectedHorseId, setSelectedHorseId] = React.useState(null);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = React.useState(
    devicePreferences.viewMode
      ? devicePreferences.viewMode
      : isMobile
        ? 'card'
        : 'table'
  );

  // 1) Use the custom hook for paginated horses
  const {
    items: horses,
    continuationToken,
    loading: isLoading,
    loadNextPage,
    filter,
    setFilter,
    sort,
    setSort,
  } = usePaginatedData(
    (options) => fetchHorsesPaginated(apiKey, organizationId, token, options),
    {
      pageSize: 25,
      // You could do server filtering by userType if needed, or do it below in client side
    }
  );

  // 2) We can filter in client side based on userType if needed:
  // But if your API already does that, you might not need it.
  useEffect(() => {
    // If userType is not admin, you might want to do something else.
  }, [userType]);

  const handleRowClick = (horseId) => {
    navigate(`/horses/detail/${horseId}`);
  };

  const handleArchiveClick = (horseId) => {
    setSelectedHorseId(horseId);
    setConfirmDialogOpen(true);
  };

  const handleArchiveConfirmation = async () => {
    // ... your existing archive logic
    setConfirmDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const isBirthdayToday = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    return (
      birthDate.getDate() === today.getDate()
      && birthDate.getMonth() === today.getMonth()
    );
  };

  const toggleViewMode = () => {
    const newViewMode = viewMode === 'table' ? 'card' : 'table';
    setViewMode(newViewMode);
    updateDevicePreferences('viewMode', newViewMode);
  };

  const structure = {
    headers: [
      { key: 'name', label: t('HorsePage.table.name'), display: { value: true, chipColor: false } },
      { key: 'breed', label: t('HorsePage.table.breed'), display: { value: true, chipColor: false } },
      { key: 'age', label: t('HorsePage.table.age'), display: { value: true, chipColor: false } },
    ],
    rows: horses.map(horse => ({
      id: horse.id,
      name: { value: horse.name, icon: null },
      breed: { value: horse.breed, icon: null },
      age: {
        value: isNaN(calculateAge(horse.birthday)) ? '' : `${calculateAge(horse.birthday)}`,
        icon: isBirthdayToday(horse.birthday) ? <CakeIcon fontSize="small" /> : null,
      },
      actions: [
        { icon: <EyeIcon />, handler: () => navigate(`/horses/detail/${horse.id}`) },
        { icon: <EditIcon />, handler: () => navigate(`/horses/edit/${horse.id}`) },
        ...(userType === 'admin'
          ? [{ icon: <ArchiveIcon />, handler: () => handleArchiveClick(horse.id) }]
          : []
        ),
      ],
    })),
  };


  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('HorsePage.horses')}
      </Typography>


      {isLoading && <LinearProgress />}

      <DataViewWrapper
        structure={structure}
        viewMode={viewMode}
        onViewModeToggle={toggleViewMode}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
        onRowClick={handleRowClick}
        pageId={'/horses'}
        toolbarActions={[
          <Button
            key="add-horse"
            variant="contained"
            color="primary"
            onClick={() => navigate('/horses/create/new')}
            startIcon={<AddIcon />}
          >
            {t('HorsePage.addHorse')}
          </Button>
        ]}
        enableFilterControls={false}
        enableSortControls={false}
      />

      {/* Load More button */}
      {continuationToken && (
        <Box mt={2} textAlign="center">
          <Button
            variant="contained"
            onClick={loadNextPage}
            disabled={isLoading}
          >
            {t('HorsePage.loadMore')}
          </Button>
        </Box>
      )}

      {/* Snackbar */}
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={confirmDialogOpen}
        title={t('HorsePage.archiveHorse')}
        content={t('HorsePage.archiveHorseConfirmation')}
        onConfirm={handleArchiveConfirmation}
        onClose={handleConfirmDialogClose}
      />


    </Paper>
  );
};

export default HorsePage;
